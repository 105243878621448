import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <div className="App-footer">
        <p>Dane osobowe są przetwarzane przez JS Sp z o. o.</p>
        <p>Administratorem danych osobowych jest JS Spółka z ograniczoną odpowiedzialnością z siedzibą w Lublinie („JS” Sp. z o.o.) ul. Smoluchowskiego 1, 20-474 Lublin, zarejestrowana w rejestrze przedsiębiorców Krajowego Rejestru Sądowego, prowadzonego przez Sąd Rejonowy Lublin Wschód VI Wydziała Gospodarczy – Krajowego Rejestru Sądowego pod numerem KRS 0000163522, NIP-9461914180, numer REGON: 430817969, tel: 667666108, e-mail: rodo@js.com.pl</p>
        <p>Dane osobowe będą przetwarzane w celu marketingu (własnych) oferowanych produktów i usług przez „JS” Sp. z o.o. (wysyłka e-maili, wysyłka SMS, kontakt telefoniczny) oraz w celach określonych w poszczególnych zgodach, które Państwo wyrazili. </p>
        <p>Podanie danych osobowych jest dobrowolne, nie jest wymogiem ustawowym lub umownym oraz nie stanowi warunku zawarcia umowy. Można wyrazić zarówno wszystkie zgody lub niektóre z nich, jak i nie wyrazić żadnej. W każdej chwili mogą Państwo cofnąć zgodę udzieloną „JS” SP. z o.o. w związku z przetwarzaniem danych osobowych bez ponoszenie negatywnych konsekwencji. Wystarczy wysłać e-mail, zadzwonić lub przesłać wiadomość poczta tradycyjną na wskazane powyżej dane kontaktowe. Cofnięcie zgody niw wpływa na legalność przetwarzania Państwa danych przed tym czynem, tzn. do momentu cofnięcia zgody przetwarzania Państwa danych przez „JS” Sp. z o.o. jest legalne.</p>
        <p>Z naszym inspektorem ochrony danych osobowych można się skontaktować<br />
          telefonicznie pod numerem telefonu 667666108 lub e-mailowo pod adresem rodo@js.com.pl
        </p>
      </div>
    );
  }
}

export default Footer;