import React, { Component } from 'react';
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';

class Line extends Component {
  render() {
    return (
      <React.Fragment>
        <Button className="title" color="link" id={this.props.name}>
          {this.props.title}
        </Button>
        <UncontrolledCollapse toggler={this.props.name}>
          <Card>
            <CardBody>
              {this.props.body}
            </CardBody>
          </Card>
        </UncontrolledCollapse>
        <br/>
      </React.Fragment>
    );
  }
}

export default Line;