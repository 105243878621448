import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Container, Row, Col } from 'reactstrap';
import Header from './components/header';
import Footer from './components/footer';
import Line from './components/_line';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      welcome: '',
      line_items: []
    };
  }

  componentDidMount() {
    axios.get('/rodo.json')
      .then(response => {
        const welcome = response.data['welcome'];
        const line_items = response.data.line_items;
        this.setState({
          welcome,
          line_items
        })
      })
      .catch(error => {
        console.log(error);
      })
  }

  render() {
    return (
      <Container>
        <Row>
          <Col xs={{ size: 12 }} sm="12" md={{ size: 10, offset: 1 }}>
            <Header welcome={this.state.welcome} />
            <div className="App-main">
              {this.state.line_items.map((item, index) => <LineItems key={index} {...item} />)}
            </div>
            <hr />
            <Footer />
          </Col>
        </Row>
      </Container>
    );
  }
}

const LineItems = (props) => (
  <Line
    name={props.id}
    title={props.title}
    body={props.body}
  />
)

ReactDOM.render(<Root />, document.getElementById('root'));

