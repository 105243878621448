import React, { Component } from 'react';

class Header extends Component {
  render() {
    return (
      <div className="App-header">
        <p>
          <img alt="JS RODO" src="logo.png" />
        </p>
        <h3>{this.props.welcome}</h3>
        <p>
          Informujemy, że dbamy o Twoją prywatność zgodnie z unijnym rozporządzeniem RODO dotyczącym ochrony danych osobowych, które weszło w życie w 2018 roku.
        </p>
        <hr />
      </div>            
    );
  }
}

export default Header;